import Player from '@vimeo/player';
import MicroModal from 'micromodal';
import debounce from 'lodash/debounce';
// FosJsRouting bundle
const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

Routing.setRoutingData(routes);

var requestRunning = false;

//************** Display Count of Remaining Learners in Contract *****************//
export function ajaxContractRemainingLearners(contractId) {
    $.ajax({
        type: 'POST',
        url: Routing.generate('user_company_remaining_learners'),
        data: {contract_id: contractId},
        dataType: 'json',
    })
        .done(function (data) {
            if (data.unlimited === false) {
                $('#count-remaining-learners').html(data.count);
                $('#block-remaining-learners').show();
            } else {
                $('#count-remaining-learners').html('');
                $('#block-remaining-learners').hide();
            }
            console.log('success');
            console.log(data);
        })
        .fail(function () {
            console.log('fail');
        });
}

//***************** Automatically save homework taker answers ********************//
export function ajaxAutoSaveHomeworkAnswer() {

    const datas = $('form[name=userbundle_homeworktaker]').serialize();

    $.ajax({
        type: 'POST',
        url: Routing.generate('user_homework_form'),
        data: datas,
        dataType: 'json',
    })
        .done(function () {
            console.log('save succeded');
        })
        .fail(function () {
            console.log('save failed');
        });

}

export function ajaxAutoSaveHomeworkAnswerTaker(step) {

    const datas = $('form[name=userbundle_homeworktaker_answer]').serialize();

    $.ajax({
        type: 'POST',
        url: Routing.generate('user_homework_question_form', {'step': step}),
        data: datas,
        dataType: 'json',
    })
        .done(function () {
            console.log('save succeded');
        })
        .fail(function () {
            console.log('save failed');
        });

}


//***************** Automatically save test taker answers ********************//
export function initAjaxAutoSaveTestAnswer() {

    var searchRequest = null;
    var searchInput = $('input');

    var searchEvents = function () {
        var datas = $('form[name=userbundle_testtaker]').serialize();

        if (searchRequest) {
            searchRequest.abort();
        }

        searchRequest = $.ajax({
            type: 'POST',
            url: Routing.generate('user_test_auto_save'),
            data: datas,
            dataType: 'json',
        })
            .done(function () {
                $('.form-save-impossible').hide();
                $('.form-save-done').show();
                setTimeout(function () {
                    $('.form-save-done').fadeOut();
                }, 1500);
            })
            .fail(function () {
                $('.form-save-impossible').show();
            });
    };

    searchInput.bind('input propertychange', debounce(searchEvents, 700));

}

//***************** Automatically save assessment taker answers ********************//
export function initAjaxAutoSaveAssessmentAnswer() {

    var searchRequest = null;
    var id = $('.js-assessment-index').data('assessmentId');

    if (requestRunning) {
        return false;
    }

    requestRunning = true;

    var datas = $('form[name=userbundle_assessmenttaker]').serialize();

    if (searchRequest) {
        searchRequest.abort();
    }

    searchRequest = $.ajax({
        type: 'POST',
        url: Routing.generate('user_assessment_auto_save', {'id_assessment_taker': id}),
        data: datas,
        dataType: 'json',
    })
        .done(function () {
            console.log('saved!');
            requestRunning = false;
        })
        .fail(function () {
            console.log('failed!');
            requestRunning = false;
        });
}

//***************** Automatically save test taker answers ********************//
export function initAjaxAutoSaveQuizAnswer() {

    var searchRequest = null;
    var searchInput = $('input');
    var path = $('.js-step').data('path');

    var searchEvents = function () {
        var datas = $('form[name=quiz_taker]').serialize();

        if (searchRequest) {
            searchRequest.abort();
        }

        searchRequest = $.ajax({
            type: 'POST',
            url: path,
            data: datas,
            dataType: 'json',
        })
            .done(function () {
                console.log('saved!');
            })
            .fail(function () {
                console.log('failed to save...');
            });
    };

    searchInput.bind('input propertychange', debounce(searchEvents, 700));

}

//***************** Automatically save test taker answers ********************//
export function initAjaxAutoSaveOpenQuizAnswer() {
    var searchRequest = null;
    var searchInput = $('textarea');
    var path = $('.js-step').data('path');

    var searchEvents = function () {
        console.log('start saving');
        var datas = $('form[name=open_quiz_taker]').serialize();

        if (searchRequest) {
            searchRequest.abort();
        }

        searchRequest = $.ajax({
            type: 'POST',
            url: path,
            data: datas,
            dataType: 'json',
        })
            .done(function () {
                console.log('saved!');
            })
            .fail(function () {
                console.log('failed to save...');
            });
    };

    searchInput.bind('textarea keyup change', debounce(searchEvents, 3000));

}

//***************** Automatically save test taker answers ********************//
export function initAjaxSaveOpenQuizAnswerTaker() {
    var searchRequest = null;
    var searchInput = $('input');
    var path = $('.js-step').data('path');

    var searchEvents = function () {
        console.log('start saving');
        var datas = $('form[name=open_quiz_taker_scores]').serialize();

        if (searchRequest) {
            searchRequest.abort();
        }

        searchRequest = $.ajax({
            type: 'POST',
            url: path,
            data: datas,
            dataType: 'json',
        })
            .done(function () {
                console.log('saved!');
            })
            .fail(function () {
                console.log('failed to save...');
            });
    };

    searchInput.bind('input change', debounce(searchEvents, 1000));

}

//******** Update database when a lesson video is played ***************//

export function initAjaxLessonVideoPlayed(iframe) {

    var player = new Player(iframe);
    var path = $('.js-step').data('path');
    var boolAutoplay = $('.js-step').data('autoplay');
    var boolIntro = $('.js-step').data('intro');
    var secondStart = boolIntro ? 3 : 0;

    let playPromise;

    $('.play-video').click(function () {
        $(this).parent().hide(); // Cache le parent du bouton
        player.setCurrentTime(secondStart); // permet de passer le jingle d'intro
        playPromise = player.play();
    });

    // Autoplay si boolAutoplay est activé
    if (boolAutoplay) {
        $('.play-video').click();
    }

    player.on('ended', function () {
        const notesModalEl = document.querySelector('#notesform');
        const newPostModalEl = document.querySelector('#new-post-modal');
        let notesModalOpen = false;
        let newPostModalOpen = false;

        if (notesModalEl !== null && notesModalEl.style.display === 'block') {
            notesModalOpen = true;
        }

        if (newPostModalEl !== null && newPostModalEl.classList.contains('is-open')) {
            newPostModalOpen = true;
        }

        $.ajax({
            method: 'POST',
            url: path,
            dataType: 'json'
        })
            .done(function (data) {
                if (data.message) {
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            player.pause();
                        });
                    }

                    alert(data.message);
                }
                if (data.redirect && !notesModalOpen && !newPostModalOpen) {
                    window.location.replace(data.redirect);
                }
            })
            .fail(function (xhr, status, error) {
                alert('Une erreur s\'est produite. Rechargez cette page et relancez votre vidéo');
                console.error(xhr.responseText, status, error);
            });
    });
}


//******** Update database when a practice video is played ***************//
export function initAjaxPracticeVideoPlayed() {

    var iframe = $('#player1')[0];
    var player = new Player(iframe);
    let playPromise;

    $('.play-video').click(function () {
        $(this).parent().hide();
        playPromise = player.play();
    });

    var id_practice_taker = $('#player1').attr('data-id-practice');
    var video = $('#player1').attr('data-video');

    player.on('ended', function () {
        $.ajax({
            method: 'POST',
            url: Routing.generate('user_practice_video'),
            data: {id_practice_taker: id_practice_taker, video: video},
            dataType: 'json'
        })
            .done(function (data) {
                if (data.message) {
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            player.pause();
                        });
                    }

                    alert(data.message);
                }
                if (data.id) {
                    $('form[name="' + data.id + '"] input[type="submit"]').click();
                }
            })
            .fail(function (xhr, status, error) {
                alert('Une erreur s\'est produite. Rechargez cette page et relancez votre vidéo');
                console.error(xhr.responseText, status, error);
            });
    });
}


//***************** Save a Note Form ********************//
export function initAjaxNoteForm() {

    var searchRequest = null;
    var searchInput = CKEDITOR.instances['note_note'];

    var searchEvents = function () {
        var text = CKEDITOR.instances['note_note'].getData();
        var id_item = $('#current_item_id').text();

        if (searchRequest) {
            searchRequest.abort();
        }

        searchRequest = $.ajax({
            type: $('.ajaxNoteForm').attr('method'),
            url: $('.ajaxNoteForm').attr('action'),
            data: {text: text, id_item: id_item}
        })
            .done(function (data) {
                $('.form-save-impossible').hide();
                $('.form-save-done').show();
                setTimeout(function () {
                    $('.form-save-done').fadeOut();
                }, 1500);
                console.log(data.status);
                initAjaxNotePreview();
            })
            .fail(function () {
                $('.form-save-done').hide();
                $('.form-save-impossible').show();
            });
    };

    searchInput.on('change', debounce(searchEvents, 1200));
}

//***************** Preview Notes ********************//
// this function is called in initAjaxNoteForm() //
export function initAjaxNotePreview() {
    var id_item = $('#current_item_id').text();
    // loading
    $('#preview-note').html('<i class="fa-solid fa-spinner fa-spin" style="color:#ccc; font-size:23px; margin-left:0px; margin-right:10px;"></i><span style="color:#ccc;">Mise à jour des notes</span>');

    $.ajax({
        type: 'POST',
        url: Routing.generate('user_step_note_preview'),
        data: {id_item: id_item}
    })
        .done(function (data) {
            $('#preview-note').html(data.response);
            console.log(data.response);
        });
}

//***************** Add/Remove a Practice Comment Upvote **************//
export function intiAjaxPracticeCommentUpvotes() {

    $(document).on('click', 'a.practice-comment-upvotes', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var datas = {comment_id: id};

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_practice_comment_upvote'),
            data: datas,
            dataType: 'json'
        })
            .done(function (data) {
                var new_heart = '';
                new_heart += '<i class="fa-solid ' + data.heart + '" ' + data.style + '></i>';

                var new_message = '';
                new_message += data.message;
                $('a#practice-comment-' + data.comment_id).html(new_heart);
                $('span#practice-comment-message-' + data.comment_id).html(new_message);
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            });
    });

}

//***************** Register Practice Comment Form ********************//
export function initAjaxPracticeCommentForm() {

    $('body').on('submit', '.ajaxPracticeCommentForm', function (e) {

        e.preventDefault();

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize()
        })
            .done(function (data) {
                // hide comment form
                $('div#practice-comment-form').hide();

                // add comment in comments list
                const new_div = ` < div class = "practice-comment flash" > < p > ${data.comment} < / p > < div class = "practice-comment-feedback" > < i class = "fa-solid fa-heart" > < / i > < / div > < / div > `;
                $('div.practice-comments-list').prepend(new_div);

                setTimeout(function () {
                    $('.practice-comment.flash').removeClass('flash');
                }, 1000);

            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            });
    });

}


//***************** Moderate comment ********************//
export function initAjaxCommentModeration() {

    $('body').on('click', '.ajaxCommentModeration', function (e) {

        e.preventDefault();

        var item = $(this).data('comment-item');
        var id = $(this).data('comment-id');
        var action = $(this).data('comment-action');

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_comment_moderation'),
            data: {item: item, id: id, action: action},
            dataType: 'json'
        })
            .done(function () {
                // change color of tr,
                // and change buttons in moderation td
                var color = '';
                var bgcolor = '';
                var html = '';
                if (action == 'undo') {
                    bgcolor = '#ecedf2';
                    html += '<button data-comment-action=\'validate\' data-comment-id=\'' + id + '\' class=\'waves-action-btn ajaxCommentModeration\' data-comment-item=\'' + item + '\' data-toggle=\'tooltip\' title=\'Valider le commentaire\'><i class=\'fa-solid fa-check\'></i></button>';
                    html += '<button data-comment-action=\'delete\' data-comment-id=\'' + id + '\' class=\'waves-action-btn ajaxCommentModeration\' data-comment-item=\'' + item + '\' data-toggle=\'tooltip\' title=\'Supprimer le commentaire\'><i class=\'fa-solid fa-trash-can\'></i></button>';
                    html += '<button class=\'waves-action-btn\' data-micromodal-trigger=\'comment-{{ comment.id }}-edit\' data-toggle=\'tooltip\' title=\'Modifier le commentaire\'><i class=\'fa-solid fa-pen-to-square\'></i></button>';
                } else if (action == 'validate') {
                    html += '<button data-comment-action=\'undo\' data-comment-id=\'' + id + '\' class=\'ajaxCommentModeration\' data-comment-item=\'' + item + '\' data-toggle=\'tooltip\' title=\'Passer comme non modéré\'><i class=\'fa-solid fa-check\'></i></button>';
                } else if (action == 'delete') {
                    color = 'lightgrey';
                    html += '<button data-comment-action=\'undo\' data-comment-id=\'' + id + '\' class=\'ajaxCommentModeration\' data-comment-item=\'' + item + '\' data-toggle=\'tooltip\' title=\'Restaurer le commentaire\'><i class=\'fa-solid fa-arrow-rotate-left\'></i></button>';
                }

                setTimeout(function () {
                    $('#comment-' + id).css('color', color);
                    $('#comment-' + id).css('background-color', bgcolor);
                    $('#comment-' + id + '-moderation').html(html);
                }, 1000);


            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            });
    });

}


//***************** Edit comment ********************//
export function initAjaxEditCommentForm() {

    $('body').on('click', '.initAjaxEditCommentForm', function (e) {

        e.preventDefault();

        var item = $(this).data('comment-item');
        var id = $(this).data('comment-id');
        var new_comment = $('#comment-textarea-' + id).val();

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_comment_edit'),
            data: {item: item, id: id, new_comment: new_comment},
            dataType: 'json'
        })
            .done(function () {
                // insert new text
                $('#comment-comment-' + id).html(new_comment);

                // display save done info
                $('.form-save-done').show();
                setTimeout(function () {
                    $('.form-save-done').fadeOut();
                }, 1500);

            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            });
    });

}

//***************** Edit Admin comment ********************//
export function initAjaxEditContractCommentForm() {

    $('body').on('click', '.initAjaxEditAdminCommentForm', function (e) {

        e.preventDefault();

        var id = $(this).data('id');
        var content = $('#textarea-edit-comment').val();

        console.log(content);

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_contract_comment_edit'),
            data: {id: id, content: content},
            dataType: 'json'
        })
            .done(function (data) {
                // insert new text
                $('#content-' + id).html(data.content);

                // close modal
                $('button.modal__close').click();

                // display save done info
                $('span.alert-' + id).show();
                setTimeout(function () {
                    $('span.alert-' + id).fadeOut();
                }, 1500);
                console.log('commentaire enregistré');
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Tu peux retenter ton action...');
            });
    });

}

//***************** Remove Admin comment ********************//
export function initAjaxRemoveContractCommentForm() {

    $('body').on('click', '.initAjaxRemoveAdminCommentForm', function (e) {

        e.preventDefault();

        var id = $(this).data('id');
        console.log(id);

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_contract_comment_delete'),
            data: {id: id},
            dataType: 'json'
        })
            .done(function () {
                // close modal
                $('button.modal__close').click();

                // remove comment line
                $('tr#' + id).addClass('bg-gray-200');
                setTimeout(function () {
                    $('tr#' + id).fadeOut('slow', function () {
                        $(this).remove();
                    });
                }, 2000);

                console.log('commentaire supprimé');

            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            });
    });

}

//***************** Edit Admin comment ********************//
export function initAjaxEditUserCommentForm() {

    $('body').on('click', '.initAjaxEditAdminCommentForm', function (e) {

        e.preventDefault();

        var id = $(this).data('id');
        var content = $('#textarea-edit-comment').val();

        console.log(content);

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_user_comment_edit'),
            data: {id: id, content: content},
            dataType: 'json'
        })
            .done(function (data) {
                // insert new text
                $('#content-' + id).html(data.content);

                // close modal
                $('button.modal__close').click();

                // display save done info
                $('span.alert-' + id).show();
                setTimeout(function () {
                    $('span.alert-' + id).fadeOut();
                }, 1500);
                console.log('commentaire enregistré');
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Tu peux retenter ton action...');
            });
    });

}

//***************** Remove Admin comment ********************//
export function initAjaxRemoveUserCommentForm() {

    $('body').on('click', '.initAjaxRemoveAdminCommentForm', function (e) {

        e.preventDefault();

        var id = $(this).data('id');
        console.log(id);

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_user_comment_delete'),
            data: {id: id},
            dataType: 'json'
        })
            .done(function () {
                // close modal
                $('button.modal__close').click();

                // remove comment line
                $('tr#' + id).addClass('bg-gray-200');
                setTimeout(function () {
                    $('tr#' + id).fadeOut('slow', function () {
                        $(this).remove();
                    });
                }, 2000);

                console.log('commentaire supprimé');

            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            });
    });

}

//******* Save exam answer taker and return results or next question ********//
export function initAjaxSaveExamAnswer(exam_taker_id, question_id, color) {

    $('body').on('click', '.initAjaxSaveExamAnswer', function (e) {

        e.preventDefault();

        if (requestRunning) {
            return false;
        }

        requestRunning = true;


        // check if an answer is checked
        if (!$('input:radio[name="question"]:checked').val()) {
            e.preventDefault();
            alert('Tu dois choisir au moins une réponse !');
            return;
        }

        // launch spinner
        $('#launch-spinner').css('display', 'block');
        $('#launch-spinner').css('opacity', '1');
        $('#question').css('opacity', '0');

        var answer_id = $('input:radio[name="question"]:checked').val();

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_exam_save'),
            data: {exam_taker_id: exam_taker_id, question_id: question_id, answer_id: answer_id, color: color},
            dataType: 'json'
        })
            .done(function (data) {

                if (data.action == 'results') {
                    location.reload();
                } else {
                    // update question id var for next ajax call
                    question_id = data.new_question_id;
                    color = data.color;

                    var answers = data.new_question_answers;
                    var html = '';
                    html += '<ol type="a">';

                    var i = 1;
                    $.each(answers, function (key, value) {
                        var sorting = i;
                        var id = key;
                        var title = value;
                        html += '<div class="flex items-center" data-target="answer-' + question_id + '-' + sorting + '">';
                        html += '<input type="radio" id="answer-' + question_id + '-' + sorting + '" name="question" value="' + id + '" class="mr-2">';
                        html += '<label class="answer" id="label-answer-' + question_id + '-' + sorting + '" for="answer-' + question_id + '-' + sorting + '">' + title + '</label>';
                        html += '<label class="answer-checkbox" id="bubble-answer-' + question_id + '-' + sorting + '" for="answer-' + question_id + '-' + sorting + '"></label>';
                        html += '</div>';

                        $(document).on('click', '#label-answer-' + question_id + '-' + sorting, function () {
                            $('input[name="question"][value="' + id + '"]').prop('checked', true);
                        });

                        i++; // increase loop index
                    });


                    html += '</ol>';
                    setTimeout(function () {
                        $('div.quizz-progress').html(data.count + 1);
                        $('h3#question-title').html(data.new_question_title);
                        $('div.quizz-content').html(html);
                        $('#launch-spinner').css('display', 'none');
                        $('#launch-spinner').css('opacity', '0');
                        $('#question').css('opacity', '1');
                        requestRunning = false;
                    }, 300);
                }
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            });
    });
}

//***************** Register School Score Form ********************//
export function initAjaxAddScoreForm() {

    $('body').on('submit', '.ajaxAddScoreForm', function (e) {

        e.preventDefault();
        $('.new-score-flash').addClass('hidden');

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize()
        })
            .done(function (data) {
                $('.new-score-flash').removeClass('hidden').html(`${data.score} points ont été ajoutés à la maison "${data.house}" !`);
            })
            .fail(function () {
                alert('BOUHHH, ça n\'a pas fonctionné...');
            });
    });

}

//***************** Duplicate TestQuestion to ExamQuestion Form ********************//
export function initAjaxTestToExamForm() {

    $('body').on('submit', '.ajaxTestToExamForm', function (e) {

        e.preventDefault();

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize()
        })
            .done(function (data) {
                // hide modal form
                $('button.modal__close').click();

                console.log(data.error_level);

                // add a success flashbag
                if (data.error_level) {
                    $('#flashbag-message').html('Attention, tu as oublié de choisir un niveau de difficulté. Les options n\'ont pas pu être enregistrées.');
                    $('div.alert-danger').show();
                } else {
                    $('#flashbag-message').html('Les options ont bien été enregistrées.');
                    $('div.alert-success').show();

                    $('#level_' + data.id_question).html(data.level);

                    if (data.state) {
                        $('#state_' + data.id_question).html('<i class="fas fa-ban red-text" title="Question inactive"></i>');
                    } else {
                        $('#state_' + data.id_question).html('');
                    }

                    if (data.exam) {
                        $('#exam_' + data.id_question).html('<i class="fas fa-check green-text"></i>');
                    } else {
                        $('#exam_' + data.id_question).html('');
                    }

                    if (data.assessment) {
                        $('#assessment_' + data.id_question).html('<i class="fas fa-check green-text"></i>');
                    } else {
                        $('#assessment_' + data.id_question).html('');
                    }
                }

            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                alert(errorThrown);
            });
    });

}

//***************** Automatically save exercise taker answers ********************//
export function initAjaxAutoSaveExerciseAnswer($id_exercise_taker) {

    var searchRequest = null;
    var searchInput = $('input, textarea');

    var searchEvents = function () {
        var datas = $('form[name=userbundle_exercisetaker]').serialize();

        if (searchRequest) {
            searchRequest.abort();
        }

        searchRequest = $.ajax({
            type: 'POST',
            url: Routing.generate('user_school_exercise_auto_save', {id: $id_exercise_taker}),
            data: datas,
            dataType: 'json',
        })
            .done(function () {
                $('.form-save-impossible').hide();
                $('.form-save-done').show();
                setTimeout(function () {
                    $('.form-save-done').fadeOut();
                }, 1500);
            })
            .fail(function () {
                $('.form-save-impossible').show();
            });
    };

    $('.editor').each(function () {
        var id = $(this).attr('id');
        CKEDITOR.instances[id].on('change', function () {
            CKEDITOR.instances[id].updateElement();
            var content = CKEDITOR.instances[id].getData();
            $('#' + id).html(content);
        });

        CKEDITOR.instances[id].on('change', debounce(searchEvents, 700));
    });

    searchInput.bind('input propertychange', debounce(searchEvents, 700));

}


//***************** Add a click link to an exercise taken ********************//
export function initAjaxSaveLinkTaker() {

    // Click on favorite profile (heart) button
    $('body').on('click', '.exercise-link', function (e) {

        e.preventDefault();

        if (requestRunning) {
            return false;
        }

        requestRunning = true;

        // get datas
        var id_link = $(this).attr('id');
        var id_answer_taker = $(this).attr('data-id-answer');
        var datas = {id_link: id_link, id_answer_taker: id_answer_taker};


        $.ajax({
            type: 'POST',
            url: Routing.generate('user_school_exercise_track_link'),
            data: datas,
            dataType: 'json'
        })
            .done(function (data) {
                window.open(data.url);
            })
            .fail(function () {
                alert('OOps ! Une erreur s\'est produite. L\'équipe est sur le coup !');
            });


        setTimeout(function () {
            requestRunning = false;
        }, 300);

    });

}


//***************** Disply or not tracking details for students ********************//
export function initAjaxDisplayTrackingDetails() {

    $(document).on('click', 'td.details-control', function (e) {
        e.preventDefault();

        var tr = $(this).closest('tr');
        var id = $(this).attr('id');
        var item = $(this).attr('data-item');
        var i = $('td#' + id + ' i');

        if (tr.hasClass('shown')) {
            // This row is already open - close it
            $('tr.details-' + id).hide();
            tr.removeClass('shown');
            i.removeClass('fa-square-minus').addClass('fa-square-plus');
        } else {
            // get data via ajax function
            if (requestRunning) {
                return false;
            }

            requestRunning = true;

            var datas = {id: id, item: item};

            $.ajax({
                type: 'POST',
                url: Routing.generate('admin_school_tracking_index'),
                data: datas,
                dataType: 'json'
            })
                .done(function (data) {
                    // Open this row
                    var details = format(data, id);
                    tr.after(details);
                    tr.addClass('shown');
                    i.removeClass('fa-square-plus').addClass('fa-square-minus');
                })
                .fail(function () {
                    alert('OOps ! Une erreur s\'est produite. L\'équipe est sur le coup !');
                });

            setTimeout(function () {
                requestRunning = false;
            }, 300);
        }

    });

    function format(d, id) {

        var $new_table = '<tr class="details-' + id + '"><td colspan="5"><table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"><tr>';

        $.each(d.results, function (key) {
            $new_table += '<th>' + key + '</th>';
        });

        $new_table += '</tr>';
        $.each(d.results, function (key, value) {
            $new_table += '<td>';
            $.each(value, function (key, val) {
                $new_table += val.name + ' ' + val.surname + '<br>';
            });
            $new_table += '</td>';
        });

        $new_table += '</tr></table></td></tr>';

        return $new_table;
    }

}


//***************** Disply or not tracking details for students ********************//
export function initAjaxResetPasswordForm() {

    $('body').on('submit', '.ajaxResetPasswordForm', function (e) {

        e.preventDefault();

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize()
        })
            .done(function (data) {
                var alert_message;
                if (data.alert == 'success') {
                    alert_message = '<p class=\'alert-success\' role=\'alert\'>' + data.forget_success + '</p>';
                } else {
                    alert_message = '<p class=\'alert-danger\' role=\'alert\'>' + data.forget_error + '</p>';
                }
                $('div.alert-result').html(alert_message);
            })
            .fail(function () {
                $('div.alert-result').html('Oops ! Une erreur s\'est produite.');
            });
    });

}

// ********* Change sorting ***********//
export function initAjaxChangeSorting(idItem, sorting, route) {
    $.ajax({
        url: Routing.generate(route),
        data: {id: idItem, sorting: sorting},
        type: 'POST',
        dataType: 'json'
    })
        .fail(function () {
            alert('Oops ! une erreur est survenue.');
        });
}

//******* Save checklist item taker ********//
export function initAjaxSaveChecklistItemTaker(checklistItemId) {

    if (requestRunning) {
        return false;
    }

    requestRunning = true;

    $.ajax({
        type: 'POST',
        url: Routing.generate('step_save_checklist_item_taker'),
        data: {id: checklistItemId},
        dataType: 'json'
    })
        .done(function (data) {
            if (data.itemDone) {
                $('#checklist-' + checklistItemId).prop('checked', true);
                $('label[for="checklist-' + checklistItemId + '"]').addClass('line-through');
            } else {
                $('#checklist-' + checklistItemId).prop('checked', false);
                $('label[for="checklist-' + checklistItemId + '"]').removeClass('line-through');
            }
        })
        .fail(function () {
            alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
        });
    requestRunning = false;
}

//******* Unlink Step from a Module ********//
export function initAjaxUnlinkStep() {

    $('body').on('click', '.initAjaxUnlinkStep', function (e) {

        e.preventDefault();

        if (requestRunning) {
            return false;
        }

        requestRunning = true;

        var id = $(this).attr('data-step');

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_module_unlink_step'),
            data: {id: id},
            dataType: 'json'
        })
            .done(function () {
                $('[data-id="' + id + '"]').remove();
                MicroModal.close('unlink-step');
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

//******* update contract of a user ********//
export function initAjaxUserUpdateContractForm() {
    $('body').on('submit', '.ajaxUserUpdateContractForm', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $('#update-contract-submit').attr('data-user-id');

        $.ajax({
            type: $(this).attr('method'),
            url: Routing.generate('user_company_update_contract', {'id': id}),
            data: $(this).serialize(),
        })
            .done(function (data) {
                $('#update-contract-form-close').click();
                buildFlashbagSuccess();

                // update datas in table
                var newSession = '<a href="' + data.contractUrl + '">' + data.contractName + '</a>';
                $('#session-' + id).html(newSession);
                $('#program-' + id).text(data.programName);
                $('.update-contract[data-user-id="' + id + '"]').attr('data-contract-id', Number(data.contractId));
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

//******* Update learners of a user ********//
export function initAjaxUserUpdateLearnersForm() {
    $('body').on('submit', '.ajaxUserUpdateLearnersForm', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $('#learners-edit-submit').attr('data-user-id');

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_company_learners_edit', {'id': id}),
            data: $(this).serialize(),
        })
            .done(function (data) {
                $('#learners-edit-form-close').click();
                $('button.learners-edit[data-user-id="' + id + '"]').attr('data-learners', data.learners);
                buildFlashbagSuccess();
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

//******* Update managers of a user ********//
export function initAjaxUserUpdateManagersForm() {
    $('body').on('submit', '.ajaxUserUpdateManagersForm', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $('#managers-edit-submit').attr('data-user-id');

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_company_managers_edit', {'id': id}),
            data: $(this).serialize(),
        })
            .done(function (data) {
                $('#managers-edit-form-close').click();
                $('button.managers-edit[data-user-id="' + id + '"]').attr('data-managers', data.managers);
                buildFlashbagSuccess();
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

//******* Update managers of a user ********//
export function initAjaxCustomDownloadReport(users) {
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', Routing.generate('download_custom_users'));

    var hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'download_custom_users_form');
    hiddenField.setAttribute('value', JSON.stringify(users));

    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
}

export function initAjaxToggleAnonymousSettings() {
    $('body').on('click', '#ajaxToggleAnonymousSettings', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $(this).data('id');

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_settings_anonymous', {'id': id}),
        })
            .done(function (data) {
                if (data.toggle) {
                    $('#ajaxToggleAnonymousSettings').addClass('toggled');
                    $('#ajaxToggleAnonymousSettings input').prop('checked', true);
                } else {
                    $('#ajaxToggleAnonymousSettings').removeClass('toggled');
                    $('#ajaxToggleAnonymousSettings input').prop('checked', false);
                }
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

export function initAjaxToggleAutoplaySettings() {
    $('body').on('click', '#ajaxToggleAutoplaySettings', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $(this).data('id');

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_settings_autoplay', {'id': id}),
        })
            .done(function (data) {
                if (data.toggle) {
                    $('#ajaxToggleAutoplaySettings').addClass('toggled');
                    $('#ajaxToggleAutoplaySettings input').prop('checked', true);
                } else {
                    $('#ajaxToggleAutoplaySettings').removeClass('toggled');
                    $('#ajaxToggleAutoplaySettings input').prop('checked', false);
                }
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

export function initAjaxToggleJingleSettings() {
    $('body').on('click', '#ajaxToggleJingleSettings', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $(this).data('id');

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_settings_jingle', {'id': id}),
        })
            .done(function (data) {
                if (data.toggle) {
                    $('#ajaxToggleJingleSettings').addClass('toggled');
                    $('#ajaxToggleJingleSettings input').prop('checked', true);
                } else {
                    $('#ajaxToggleJingleSettings').removeClass('toggled');
                    $('#ajaxToggleJingleSettings input').prop('checked', false);
                }
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

export function initAjaxToggleCompanySharing() {
    $('body').on('click', '#ajaxToggleCompanySharing', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $(this).data('id');

        $.ajax({
            type: 'POST',
            url: Routing.generate('user_company_config_sharing', {'id': id}),
        })
            .done(function (data) {
                if (data.toggle) {
                    $('#ajaxToggleCompanySharing').addClass('toggled');
                    $('#ajaxToggleCompanySharing input').prop('checked', true);
                } else {
                    $('#ajaxToggleCompanySharing').removeClass('toggled');
                    $('#ajaxToggleCompanySharing input').prop('checked', false);
                }
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

export function initAjaxToggleCompanyDefaultFilter() {
    $('body').on('click', '.ajaxToggleCompanyDefaultFilter', function (e) {
        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var id = $(this).data('id');

        $.ajax({
            type: 'POST',
            url: Routing.generate('admin_company_default_filter', {'id': id}),
        })
            .done(function (data) {
                if (data.toggle) {
                    $('.ajaxToggleCompanyDefaultFilter[data-id=' + id + ']').html('<i class="fa-solid fa-toggle-on fa-lg"></i>');
                } else {
                    $('.ajaxToggleCompanyDefaultFilter[data-id=' + id + ']').html('<i class="fa-solid fa-toggle-off fa-lg"></i>');
                }
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Retente ton action...');
            });
        requestRunning = false;
    });
}

function buildFlashbagSuccess() {
    var div = '<div class="alert alert-icon alert-dismissible alert-success" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span></button>'
        + '<div class="flex items-center mt-3">'
        + '<i class="fa-solid fa-check mr-2"></i>'
        + '<p>Ton action a bien été enregistrée.</p>'
        + '</div></div>';

    $('.alert-div').html(div);

    $('.alert > .close').on('click', function () {
        $(this).parent().fadeOut(250);
    });
}

// ********* Change consultation state ***********//
export function initAjaxSwitchConsultationState($this, idItem, stateTd) {
    $.ajax({
        url: Routing.generate('admin_consultation_change_state'),
        data: {id: idItem},
        type: 'POST',
        dataType: 'json'
    })
        .done(function (data) {
            if (!data.state) {
                $this.text('Publier');
                stateTd.text('Hors-ligne');
            } else {
                $this.text('Hors-ligne');
                stateTd.html('<span class="font-semibold">Publiée</span><br><span class="small">À l\'instant</span>');
            }
        })
        .fail(function () {
            alert('Oops ! une erreur est survenue.');
        });
}

//***************** Register Practice Comment Form ********************//
export function initAjaxSubscribeEvent() {

    $('body').on('change', '.ajaxSubscribeEvent', function (e) {

        e.preventDefault();
        if (requestRunning) {
            return false;
        }
        requestRunning = true;

        var element = $(this);
        var idEvent = element.data('idEvent');
        var value = element.val();

        $('#select-icon-' + idEvent).html('<i class="fa-regular fa-lg fa-spinner fa-spin-pulse fa-margin-right"></i>');

        $.ajax({
            url: Routing.generate('user_community_event_subscribe', {id: idEvent}),
            data: {value: value},
            type: 'POST',
            dataType: 'json'
        })
            .done(function () {
                // update text in select option with value "unsubscribe"
                if (value == '1') {
                    $('#select-icon-' + idEvent).html('<i class="fa-regular fa-lg fa-check green-text fa-margin-right"></i>');
                } else if (value == '0') {
                    $('#select-icon-' + idEvent).html('<i class="fa-regular fa-lg fa-xmark red-text fa-margin-right"></i>');
                } else {
                    $('#select-icon-' + idEvent).html('&nbsp;&nbsp;&nbsp;&nbsp;');
                }
            })
            .fail(function () {
                alert('Une erreur s\'est produite. Veuillez réessayer votre action...');
            })
            .always(function () {
                requestRunning = false;
            });
    });
}
